.social-container {
  /* background: #eee;
  padding: 25px 50px; */
}

.social__text {
  font-size: 16px;
  color: black;
  margin-top: 29px;
  padding-bottom: 10px;
  font-weight: 400;
}

a.social {
  transition: transform 250ms;
  display: inline-block;
  padding: 5px;
}

a.social:hover {
  transform: translateY(-5px);
}

a.youtube {
  color: #eb3223;
}

a.facebook {
  color: #4968ad;
}

a.twitter {
  color: #49a1eb;
}

a.instagram {
  color: black;
}

a.whatsapp {
  color: #25d366;
}
