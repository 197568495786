.about__content--separator {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #54af36;
  width: 10%;
}

.about__contents {
  margin-top: 60px;
  margin-bottom: 30px;
}

.about__content {
  margin-top: 30px;
}
